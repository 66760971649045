<template>
  <div>
    <div class="pb-5">{{ $t('LANDING_PAGE.CUSTOMISATION_TITLE') }}</div>
    <v-row>
      <v-col sm="6" xs="12">
        <div class="office-picker mb-2">
          <span>{{ $t('LANDING_PAGE.BACKGROUND') }}</span>
          <div class="office-picker__wrapper d-flex">
            <v-text-field
              v-model="theme.background"
              v-mask="mask"
              id="background"
              hide-details
              class="office-picker__cange-color mr-2 pa-0" 
              outlined
              dense
              @blur="optimizeInputValue($event)"
            />
            <div
              @click="showBackgroundPicker = !showBackgroundPicker"
              class="color-picker-wrapper"
            >
              <div class="color-picker" :style="{ 'background-color': theme.background }" />
            </div>
          </div>
          <v-color-picker
            v-if="showBackgroundPicker"
            v-click-outside="onClickOutside"
            v-model="theme.background"
            class="color-picker-display"
            mode="hexa"
          />
        </div>
        <div class="office-picker mb-2">
          <span>{{ $t('LANDING_PAGE.PRIMARY') }}</span>
          <div class="office-picker__wrapper d-flex">
            <v-text-field
              v-model="theme.primary"
              v-mask="mask"
              id="primary"
              hide-details
              class="office-picker__cange-color mr-2 pa-0"
              outlined
              dense
              @blur="optimizeInputValue($event)"
            />
            <div
              @click="showPrimaryPicker = !showPrimaryPicker"
              class="color-picker-wrapper"
            >
              <div class="color-picker" :style="{ 'background-color': theme.primary }" />
            </div>
          </div>
          <v-color-picker
            v-if="showPrimaryPicker"
            v-click-outside="onClickOutside"
            v-model="theme.primary"
            class="color-picker-display primary-display"
          />
        </div>
        <div class="office-picker mb-2">
          <span>{{ $t('LANDING_PAGE.SECONDARY') }}</span>
          <div class="office-picker__wrapper d-flex">
            <v-text-field
              v-model="theme.secondary"
              v-mask="mask"
              id="secondary"
              hide-details
              class="office-picker__cange-color mr-2 pa-0"
              outlined
              dense
              @blur="optimizeInputValue($event)"
            />
            <div
              @click="showSecondaryPicker = !showSecondaryPicker"
              class="color-picker-wrapper"
            >
              <div class="color-picker" :style="{ 'background-color': theme.secondary }" />
            </div>
          </div>
          <v-color-picker
            v-if="showSecondaryPicker"
            v-click-outside="onClickOutside"
            v-model="theme.secondary"
            class="color-picker-display secondary-display"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="4">
            <span>{{ $t('LANDING_PAGE.SELECT_LOGO') }}</span>
          </v-col>
          <v-col cols="2">
            <InputImage
              v-model="theme.logo"
              class="select-image-input"
              :formats="[
                {name: 'lg', height: 200, encoding: 'png' },
                {name: 'md', height: 100, encoding: 'png' },
                {name: 'sm', height: 30, encoding: 'png' }
              ]"
              :label="$t('LANDING_PAGE.SELECT_LOGO')"
              :value="theme.logo"
              @input="$emit('input', {theme:{logo: $event}})"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <span>{{ $t('LANDING_PAGE.UPLOAD_FAVICON') }}</span>
          </v-col>
          <v-col cols="2">
            <InputImage
              v-model="theme.favicon"
              class="select-image-input"
              :formats="[
                {name: 'lg', width: 16, height: 16, encoding: 'vnd.microsoft.icon' }
              ]"
              :label="$t('LANDING_PAGE.UPLOAD_FAVICON')"
              :value="theme.favicon"
              @input="$emit('input', {theme:{favicon: $event}})"
            />
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputImage from '@/components/InputImage'

export default {
  name:"FormOfficeCompactLandingInfo",
  components: {
    InputImage,
  },
  data: (_this) => ({
    background: '#F3F2F2',
    showBackgroundPicker: false,
    showPrimaryPicker: false,
    showSecondaryPicker: false,
    theme: {
      background: '#F3F2F2',
      primary: '#201f35',
      secondary: '#e0bc37',
      logo: undefined,
      favicon: undefined,
    },
    mask: '#HHHHHHHH',
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'theme' && !(Object.keys(values[key]).lenght)) {
          return
        }
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },
    onClickOutside () {
      this.showBackgroundPicker = false
      this.showPrimaryPicker = false
      this.showSecondaryPicker = false
    },
    optimizeInputValue (event) {
      const { id, value } = event.target
      const themeProp = this.theme[id]
      let copyValue = value

      if (value[0] !== '#') {
        const valueArr = value.split('')
        valueArr.unshift('#')
        copyValue = valueArr.join('')
        this.theme[id] = copyValue
      }

      const length = copyValue.length
      let valueArr = copyValue.split('')
      switch(length) {
        case 1:
          valueArr.push(...[0,0,0,0,0,0])
          this.theme[id] = valueArr.join('')
          break
        case 2:
          valueArr.push(...[0,0,0,0,0])
          this.theme[id] = valueArr.join('')
          break
        case 3:
          valueArr.push(0, 0, 0, 0)
          this.theme[id] = valueArr.join('')
          break
        case 4:
        case 5:
          this.theme[id] = this.transformStr(copyValue)
          break
        case 6:
        case 8:
          this.theme[id] += themeProp[themeProp['length']-1]
          break
      }
    },
    transformStr (value) {
      const fullArr = []
      const valueArr = value.split('')

      valueArr.forEach((item, index) => {
        if (index === 0) {
          fullArr.push(item)
          return
        }

        fullArr.push(item)
        fullArr.push(item)
      })

      return fullArr.join('')
    },
  },
  watch: {
    values: {
      handler (n) {
        const { background, primary, secondary, logo, favicon } = this.values.theme

        if (!background) {
          this.values.theme.background = '#F3F2F2'
        } else {
          this.theme.background = background
        }

        if (!primary) {
          this.values.theme.primary = '#201f35'
        } else {
          this.theme.primary = primary
        }

        if (!secondary) {
          this.values.theme.secondary = '#e0bc37'
        } else {
          this.theme.secondary = secondary
        }

        if (logo) {
          this.theme.logo = logo
        } else {
          this.theme.logo = null
        }

        if (favicon) {
          this.theme.favicon = favicon
        } else {
          this.theme.favicon = null
        }

        this.attribValues()
      },
      deep: true,
    },
    'theme.background' () {
      this.$emit('input', {theme:{background: this.theme.background}})
    },
    'theme.primary' () {
      this.$emit('input', {theme:{primary: this.theme.primary}})
    },
    'theme.secondary' () {
      this.$emit('input', {theme:{secondary: this.theme.secondary}})
    },
  },
}
</script>

<style scoped lang="scss">
.office-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.office-picker__cange-color {
  width: 120px;
}
.color-picker-wrapper {
  border: 1px solid gray;
  width: 40px;
  height: 40px;
  padding: 1px;
  cursor: pointer;
}
.color-picker {
  width: 100%;
  height: 100%;
}
.color-picker-display {
  position: absolute;
  right: 36px;
  z-index: 10;
}
.primary-display {
  margin-top: -55px;
}
.secondary-display {
  margin-top: -107px;
}
</style>